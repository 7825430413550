import React, { useEffect } from 'react'
import AppLayout from '../../components/layout';
import "./help.css";

const Help = () => {
    return (
        <AppLayout>
            <div className="help">
                <div className="help_content">
                    <h3>Coming Soon...</h3>
                </div>
            </div>
        </AppLayout>
    )
}
export default Help;